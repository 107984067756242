exports.onClientEntry = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations('/').then(registrations => {
      registrations.map(reg => reg.unregister())
    })
  }
}

exports.onInitialClientRender = () => {
  // require fonts from typeface here
  // require('typeface-montserrat')
}
