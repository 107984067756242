module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js","omitGoogleFont":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"starter","short_name":"str","start_url":"/","background_color":"#fff","theme_color":"#13233C","display":"minimal-ui","icon":"static/img/icon_310x310.png","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"0a68b26d15d3a5ab56f7b57b0a7c6343"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
